#root {
  margin: 0;
  padding: 0;
  width: 100%;
}

body {
  background: rgba(21, 20, 20, 0.941);
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid oklch(0.623 0.214 259.815);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-left: 10px;
  margin-right: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spinner-text {
  z-index: 3;
  text-align: center;
  font-size: 12px;
  color: white;
}

.textarea::selection {
  background-color: rgb(35, 66, 149);
}


/* for landing page carousel */
.carousel {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Adjust height as needed */
  position: relative;
  overflow: hidden;
  width: 100%; /* Ensure the carousel takes up the full width of its container */
}

.message {
  color: white;
  position: absolute;
  text-align: center;
  transition: transform 0.5s ease, opacity 0.5s ease, font-size 0.5s ease;
  opacity: 0.7;
  font-size: 18px;
}

.message-center {
  transform: translateX(0) scale(1.2);
  opacity: 1;
  font-size: 24px;
}

.video-demo {
  border: 3px solid #808080;
  border-radius: 5px;
}


input[type="time"] {
  color: black; /* Or any dark color */
  background-color: white; /* Or any light color */
}

/* For browser-specific pseudo-elements (Chrome, Edge, etc.) */
input[type="time"]::-webkit-datetime-edit-second-field,
input[type="time"]::-webkit-datetime-edit-minute-field,
input[type="time"]::-webkit-datetime-edit-hour-field {
  color: black; /* Explicitly set color for all parts */
}